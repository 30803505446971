<template>
    <div class="rq-container content-wrapper doc-mgmt-add">
        <div class="row">
            <div class="col col-2">
                <div class="category-list d-flex flex-column">
                    <div class="instruction-text">Click or drop to Category</div>
                    <div v-for="category in fileScanCategories"
                        :key="category.id"
                        :id="`div_category_${category.id}`"
                        @click="onCategoryClick($event, category.id)"
                        @drop="onCategoryDrop($event, category.id)"
                        @dragover="onCategoryDragOver($event, category.id)"
                        :class="{'rq-doc-category': true, 'rq-doc-category-active': (category.id == fileScanCategoryID)}">
                            {{category.name}} {{getCategoryCount(category.id)}}
                    </div>
                </div>
            </div>
            <div class="col col-10">
                <rq-banner
                    icon="fas fa-exclamation-circle"
                    variant="error"
                    :message="errorMessage"
                    :visible="errorMessage.length > 0"
                    @dismiss="errorMessage=''"
                    dismissable
                />
                <rqdx-action-data-grid
                    ref="dataGrid"
                    automation_id="tbl_file_scan_documents_add"
                    :actions="selectionActions"
                    :config="gridConfig"
                    :data-source="gridDataSource"
                    :export-file-name="elementName('', 'data')"
                    @delete="onDeleteItem"
                    fixed-header
                    hide-search
                    hide-export
                    hide-show-column-chooser
                    rq-editable
                />
            </div>
        </div>
        <form enctype="multipart/form-data" novalidate ref="fileForm">
            <input type="file" ref="fileInput" id="fileInput" name="fileInput" @change="onFileInputChange($event)" :accept="acceptedAttr" class="input-file" multiple  />
        </form>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { UPDATE_SESSION_USER } from '../../../store/actions';
    import { UserSecuritySetting } from "@/shared/models/models";
    import { DocumentFileType, FileScanDocumentType }  from "../../documents/enums";
    import { FileScanDocumentDto, FileScanDocumentDataDto, FileScanDocumentDescriptionDefaults }  from "../models";
    import { FileScanDocumentDuplicateAction }  from "../enums";
    import { SystemLookupItem } from "@/shared/models/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name: 'FileScanDocumentsAdd',
        components: { },
        props: {
            existingItems: { type: Array, required: true, default: () => []}
        },
        data() {
            return {
                acceptedFileTypes: [".bmp",".doc", ".docm",".docx", ".gif", ".htm", ".html", ".jpg", ".jpeg", ".msg", ".pdf", ".png", ".pptx", ".rtf", ".tif", ".tiff", ".txt", ".xls", ".xlsx", ".xml", ".zip", ".eml"],
                fileScanDescriptionID: 0,
                fileScanCategoryID: null,
                items: [],
                itemData: [],
                isDragging: false,
                invalidFile: false,
                invalidMessage: "",
                maxUploadSize: 100*1024*1024,//100Mb
                totalSizeDisplay: "",
                errorMessage: "",
                documentDescriptionDefaults: []
            }
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            showSetDefaultCategory() { return !_.isEqual(this.fileScanCategoryID, this.user.defaultCategory); },
            acceptedAttr() { return _.join(this.acceptedFileTypes, ","); },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
            fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
            localSecurity(){ return this.securitySettings.findValues(["CanReplaceDocuments", "PredefinedDescriptions"]); },
        },
        methods: {
            clear() {
                if (!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            clearForm() {
                if (!this.fileForm) return;
                this.fileForm.reset();
                if (!this.fileInput) return;
                this.fileInput.value = null;
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocumentDescriptionDefaults();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.documentDescriptionDefaults = _.map(result, i => new FileScanDocumentDescriptionDefaults(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading description defualts.` });
                        return error;
                    });
            },

            deleteItemData(keys) {
                _.each(keys, k => {
                    _.remove(this.itemData, (i) => {return _.get(i, "name", "") == k;});
                });
            },

            getCategoryCount(categoryID) {
                let categoryCount = _.size(_.filter(this.items, ["fileScanCategoryID", categoryID]));
                return categoryCount == 0 ? `` : `(${categoryCount})`;
            },

            getExistingFileScanCategoryID(item){
                let dup = {};
                dup = _.find(this.existingItems, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return dup.fileScanCategoryID;
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            isUniqueItemExisting(item){
                let dup = {};
                dup = _.find(this.existingItems, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                           && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1);
                });
                return _.isEmpty(dup);
            },

            isUniqueItem(item){
                let dup = {};
                dup = _.find(this.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description))
                        && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1)
                        && _.parseNumber(i.fileScanDocumentID, -1) != _.parseNumber(item.fileScanDocumentID, -1)
                        ;
                });
                return _.isEmpty(dup);
            },

            isValidFileExt(fileExt){
                return _.includes(this.acceptedFileTypes, _.toLower(fileExt));
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    onEditorPreparing: self.onEditorPreparing,
                    paging: { enabled: false },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false},
                        {
                            dataField: "description",
                            dataType: "string",
                            caption: "Description",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            minWidth: 300,
                            setCellValue: function(rowData, data) {
                                var description = _.find(self.fileScanDescriptions, t => t.id == data.value.id);
                                var tags = _.filter(self.documentTags, t => t.id == data.value.id);
                                var defaults = _.filter(self.documentDescriptionDefaults, x => x.descriptionID == data.value.id);
                                rowData.fileScanDescriptionID = data.cellData.fileScanDescriptionID = data.value.id;
                                rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = defaults[0]?.defaultCategoryID || null;
                                rowData.tagIDs = data.cellData.tagIDS = defaults[0]?.tagIDs || null;
                                rowData.description = data.cellData.description = data.value.name;
                                rowData.useDefaultDescription = data.cellData.useDefaultDescription = (data.value.id > 0);
                                self.setItemData(data.cellData);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                let customItem = new SystemLookupItem({id: 0, name: cellInfo.data.description});
                                let fileScanDescriptionsPlus = _.concat([customItem], _.sortBy(self.fileScanDescriptions, "name"));
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return fileScanDescriptionsPlus;
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    acceptCustomValue: !self.localSecurity.PredefinedDescriptions,
                                    value: cellInfo.data.fileScanDescriptionID,
                                    onValueChanged: function(e) {
                                        let fileScanDescription = _.find(fileScanDescriptionsPlus, ['id', e.value]);
                                        cellInfo.setValue({value: fileScanDescription, cellData: cellInfo.data});
                                    },
                                    onCustomItemCreating: function(e) {
                                        customItem.name = e.text;
                                        e.customItem = customItem;
                                        _.assign(fileScanDescriptionsPlus[0], customItem);
                                        cellInfo.setValue({value: customItem, cellData: cellInfo.data});
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { dataField: "fileType", dataType: "number", caption: "Type", calculateSortValue: "fileTypeDisplay", width: 70,
                            lookup: {
                                dataSource: self.documentFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            }
                        },
                        { dataField: "fileScanCategoryID", dataType: "number", caption: "Category",
                            lookup: {
                                dataSource: self.fileScanCategories,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            editorOptions: {
                                dropDownOptions: {
                                    minWidth: 200
                                }
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.fileScanCategories;
                                        }
                                    },
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.data.fileScanCategoryID,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                }).appendTo(cellElement);
                            },
                            setCellValue: function(rowData, data) {
                                rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = data.value;
                                self.setItemData(data.cellData);
                            },
                        },
                        {
                            dataField: "fileScanDocumentDuplicateAction",
                            dataType: "string",
                            caption: "Action",
                            alignment: "left",
                            minWidth: 75,
                            lookup: {
                                dataSource: self.documentActions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            setCellValue: function(rowData, data) {
                                rowData.fileScanDocumentDuplicateAction = data.cellData.fileScanDocumentDuplicateAction = data.value;
                                if (data.value == FileScanDocumentDuplicateAction.CreateNew && data.cellData.fileScanDescriptionID == 0) {
                                    rowData.description = data.cellData.description = `${data.cellData.description}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
                                    rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = self.fileScanCategoryID;
                                }
                                if (data.value == FileScanDocumentDuplicateAction.ReplaceExisting && data.cellData.fileScanDescriptionID == 0) {
                                    rowData.description = data.cellData.description = data.cellData.standardDescription;
                                    rowData.fileScanCategoryID = data.cellData.fileScanCategoryID = self.getExistingFileScanCategoryID({description: data.cellData.description, fileType: data.cellData.fileType});
                                }
                                self.setItemData(data.cellData);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.documentActions;
                                        }
                                    },
                                    searchEnabled: true,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.data.fileScanDocumentDuplicateAction,
                                    disabled: !_.get(cellInfo.data, 'requiresAttention', false),
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                }).appendTo(cellElement);
                            }
                        },
                        {
                            dataField: "tagIDs",
                            dataType: "string",
                            caption: "Tags",
                            calculateSortValue: "tagNames",
                            minWidth: 120,
                            setCellValue: function(rowData, data) {
                                let itemNames = _.map(data.value, id => _.find(self.documentTags, { id }).name);
                                let tagNames = _.join(itemNames, ", ");
                                rowData.tagIDs = data.cellData.tagIDs = data.value;
                                rowData.tagNames = data.cellData.tagNames = tagNames;
                                self.setItemData(data.cellData);
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                $("<span />")
                                    .addClass("text-truncate")
                                    .append(cellInfo.data.tagNames)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.documentTags;
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: true,
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({value: e.value, cellData: cellInfo.data});
                                    },
                                    onOpened: function(e) {
                                        $('.dx-list-select-all').hide()
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                        { dataField: "dateModifiedUtcDisplay", caption: "Date Modified", visible: false},
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    summary: {
                        totalItems: [{
                                name: "UploadSummary",
                                showInColumn: "description",
                                displayFormat: "Total Upload Size: {0}",
                                summaryType: "custom"
                            }
                        ],
                        calculateCustomSummary(options) {
                            if (options.name !== "UploadSummary") return;
                            options.totalValue = self.totalSizeDisplay;
                        }
                    },
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                    update: self.onGridUpdate
                };
            },
            initNonReactiveVariables() {
                const self = this;
                self.fileScanCategoryID = self.user.defaultCategory
                self.itemTypeName = "Document";
                self.itemTypeNamePlural = "Documents";
                self.itemKey = "fileScanDocumentID";
                self.readOnlyColumns = ["fileScanPageFileName", "fileType", "dateModifiedUtcDisplay"];
                self.documentActions = self.localSecurity.CanReplaceDocuments
                                     ? FileScanDocumentDuplicateAction.lookupItems
                                     : _.filter(FileScanDocumentDuplicateAction.lookupItems, function(i) { return i.id != FileScanDocumentDuplicateAction.ReplaceExisting ; });
                self.documentFileTypes = DocumentFileType.lookupItems;
                self.fileScanCategories = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_CATEGORIES, self.order.regionID);
                self.fileScanDescriptions = self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_DESCRIPTIONS, self.order.regionID);
                self.documentTags = self.lookupHelpers.getLookupItems(self.lookupItems.DOCUMENT_TAGS);

                self.selectionActions = [
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }
                    ];
            },

            onCategoryClick(e, id) {
                this.fileScanCategoryID = id;
                if (!this.fileInput) return;
                this.fileInput.click();
            },

            onCategoryDrop(e, id) {
                const self = this;
                e.preventDefault();
                let files = [];
                if (e.dataTransfer.items) {
                    for (var i = 0; i < e.dataTransfer.items.length; i++) {
                        if (e.dataTransfer.items[i].kind === 'file') {
                            var file = e.dataTransfer.items[i].getAsFile();
                            if (self.isValidFileExt(file.name.substr(file.name.lastIndexOf('.')))) {
                                files.push(file);
                            } else {
                                self.errorMessage = `Invalid File ${file.name}, it will be excluded.`;
                            }
                        }
                    }
                } else {
                    for (var j = 0; j < e.dataTransfer.files.length; j++) {
                        var file2 = e.dataTransfer.files[j];
                        if (self.isValidFileExt(file2.name.substr(file2.name.lastIndexOf('.')))) {
                            files.push(file2);
                        } else {
                            self.errorMessage = `Invalid File ${file2.name}, it will be excluded.`;
                        }
                    }
                }
                if (files.length == 0) return;
                this.processFiles(files);
            },

            onCategoryDragOver(e, id) {
                e.preventDefault();
                this.fileScanCategoryID = id;
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, "fileScanPageFileName");
                    _.pullAllBy(self.items, items, self.itemKey);
                    self.deleteItemData(toBeDeletedKeys);
                    let message = toBeDeletedKeys.length > 1 ? `${toBeDeletedKeys.length} ${self.itemTypeNamePlural} were removed from the queue.` : `${self.itemTypeName} was removed from the queue.`
                    self.$toast.success({ message: message });
                    self.refresh();
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onFileInputChange(e) {
                if (!e.target.files.length) return;
                this.processFiles(e.target.files);
            },

            onGridUpdate(key, values) {
                return;
            },

            onSetDefaultCategory(e) {
                this.saveDefaultCategory(this.fileScanCategoryID)
            },

            processFiles (files = []) {
                if (files.length == 0) return;
                const self = this;

                let fileScanDocumentID = 0;
                let maxfileScanDocument = _.minBy(self.items, 'fileScanDocumentID') || {};
                if (!_.isEmpty(maxfileScanDocument)) {
                    fileScanDocumentID = maxfileScanDocument.fileScanDocumentID;
                }
                _.each(files, f => {
                    fileScanDocumentID--;
                    let fileExt = f.name.substr(f.name.lastIndexOf('.'));
                    let description = f.name.substr(0, f.name.lastIndexOf('.'));
                    let fileType = DocumentFileType.fromFileExtension(fileExt.toLowerCase());
                    let doc = new FileScanDocumentDto({
                        fileScanDocumentID: fileScanDocumentID,
                        ordersID: self.orderId,
                        fileScanPageFileName: f.name,
                        contentType: f.type,
                        fileSize: f.size,
                        description: description,
                        standardDescription: description,
                        numberOfPages: 1,
                        documentType: FileScanDocumentType.Attached,
                        fileScanDescriptionID: 0,
                        fileTypeDisplay: fileExt.toLowerCase(),
                        fileType: fileType,
                        dateModifiedNumber: f.lastModified,
                        tagIDs: []
                    });
                    if (!self.isUniqueItemExisting({description, fileType})) {
                        //this doc exists, so set the category and action type
                        doc.requiresAttention = true;
                        doc.fileScanCategoryID = self.getExistingFileScanCategoryID({description, fileType});
                        doc.fileScanDocumentDuplicateAction = self.localSecurity.CanReplaceDocuments ? FileScanDocumentDuplicateAction.ReplaceExisting : FileScanDocumentDuplicateAction.CreateNew;
                        doc.description = self.localSecurity.CanReplaceDocuments ? doc.description : `${doc.description}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
                    } else {
                        doc.requiresAttention = false;
                        doc.fileScanCategoryID = self.fileScanCategoryID;
                        doc.fileScanDocumentDuplicateAction = 0;
                    }
                    if (self.isUniqueItem(doc)) {
                        self.items.push(doc);
                        self.itemData.push(f);
                    } else {
                        self.errorMessage = `Document with file type [${doc.fileTypeDisplay}] and Description [${doc.description}] is already in queue.`;
                    }
                });
                self.clearForm();
                self.refresh();
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(_.indexOf(this.readOnlyColumns, e.dataField) >= 0) {e.editorOptions.disabled = true; return;}
                if(e.dataField == "fileScanDocumentDuplicateAction" && _.getNumber(e, "row.data.fileScanDocumentDuplicateAction", 0) == 0) {e.editorOptions.disabled = true; return;}
            },

            refresh() {
                const self = this;
                self.clear();
                self.gridInstance.refresh();
                self.validate();
            },

            removeIgnored() {
                const self = this;
                let ignoredItems = _.filter(self.items, ["fileScanDocumentDuplicateAction", FileScanDocumentDuplicateAction.Ignore]);
                _.pullAllBy(self.items, ignoredItems, self.itemKey);
                let toBeDeletedKeys = _.map(ignoredItems, "fileScanPageFileName");
                self.deleteItemData(toBeDeletedKeys);
            },

            saveDefaultCategory(defaultCategory){
                const self = this;
                let original = _.clone(self.user);
                let newUser = _.clone(self.user);
                newUser.defaultCategory = defaultCategory;
                let changes = self.getAuditChanges(original, newUser);
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let storePromise = self.$store.dispatch(UPDATE_SESSION_USER, {user: newUser, changes: changes});
                self.$rqBusy.wait(storePromise)
                    .then(user => {
                        let fileScanCategory = _.find(self.fileScanCategories, ['id', user.defaultCategory]) || {};
                        let fileScanCategoryName = '(Blank)';
                        if (!_.isEmpty(fileScanCategory)) fileScanCategoryName = fileScanCategory.name;
                        self.$toast.success({ message: `User Default Category set to ${fileScanCategoryName}.` });
                    }).catch(e => {
                        console.log(e.message);
                        self.$toast.error({ message: `Error Setting Default Category.` });
                    });
            },

            saveDocuments(){
                const self = this;
                self.removeIgnored();
                const formData = new FormData();
                _.each(self.itemData, f => {
                    formData.append(`file-${f.name}`, f);
                });
                formData.append('data-', JSON.stringify(self.items));
                let apiPromise = self.$api.FileScanApi.uploadToFileScan(formData);
                return self.$rqBusy.wait(apiPromise);
            },

            setItemData(data){
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(data, self.itemKey)]);
                self.errorMessage = "";
                let item = new FileScanDocumentDto(_.assign({}, self.items[itemIndex], data));
                if (self.localSecurity.PredefinedDescriptions && !item.useDefaultDescription && item.fileScanDescriptionID == 0 && item.fileScanDocumentDuplicateAction != FileScanDocumentDuplicateAction.Ignore) {
                    self.errorMessage = `Please select a Predefined Description.`;
                    _.assign(self.items[itemIndex], item);
                    return;
                }
                if (!self.isUniqueItem(item) && item.fileScanDocumentDuplicateAction == 0) {
                    let fileScanDescription = _.parseNumber(item.fileScanDescriptionID, 0) > 0 ? self.lookupHelpers.getLookupItems(self.lookupItems.FILE_SCAN_DESCRIPTIONS, item.fileScanDescriptionID) : self.description;
                    self.errorMessage = `Document with file type [${item.fileTypeDisplay}] and Description [${item.description}] is already in use.`;
                    return;
                }
                _.assign(self.items[itemIndex], item);
                self.validate();
            },

            validate() {
                const self = this;
                self.errorMessage = "";
                let totalUploadSize = _.sumBy(self.items, "fileSize");
                for (let aMultiples = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], nMultiple = 0, nApprox = totalUploadSize / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
                    self.totalSizeDisplay = nApprox.toFixed(3) + " " + aMultiples[nMultiple];
                }
                if (totalUploadSize > self.maxUploadSize) {
                    self.errorMessage = "You have gone over the maximum upload size, please remove files.";
                }
                let invalidCount = _.size(_.filter(self.items, i => i.requiresAttention == true && i.fileScanDocumentDuplicateAction == 0));
                if (invalidCount > 0) {
                    self.errorMessage = `You have ${invalidCount} record${invalidCount == 1 ? '' : 's'} that requires attention, please select an action.`;
                    self.$emit("disable-ok");
                }
                self.$emit(`${self.errorMessage.length > 0 ? 'disable' : 'enable'}-ok`);
            },
        }
    }
</script>